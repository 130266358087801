import mixpanel from './mixpanel';

export const trackPageView = (source: string, tabName: string, sessionId: string | null) => {
    const pageNameMapping: Record<string, string> = {
        "Home": "home",
        "Spending": "spending",
        "Finley AI": "ai_chat",
        "Meet Coach": "coaching",
        "Settings": "settings",
        "Connected Accounts": "connected_accounts",
        "Account Details": "account_details",
        "Support": "support",
    };

    const resolvedPageName = pageNameMapping[tabName] || tabName.toLowerCase();
    mixpanel.track("page_view", {
        page_name: resolvedPageName,
        source:  pageNameMapping[source] || source,
        session_id: sessionId,
        timestamp: new Date().toISOString(),
    });
};
