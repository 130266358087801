import { useNavigate } from 'react-router';
import { ArrowRight } from 'lucide-react';
import carouselImage from "../../../assets/carouselHome.svg"; // Make sure the image path is correct
import mixpanel from '../../../utils/mixpanel';
interface SpendingAnalysisCardProps {
  mixPanelSource: string;
}

// Define the functional component
const SpendingAnalysisCard: React.FC<SpendingAnalysisCardProps> = ({ mixPanelSource }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    mixpanel.track("spending_plan_start", {
      source: mixPanelSource // Adjust source as needed
    });
    navigate("/new-connect-account");
  };

  return (
    <div className="flex mt-3 p-[25px] flex-col gap-[25px] self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
      {/* Carousel Image */}
      <div>
        <div className="flex pb-[25px] justify-center h-[224px]">
          <img src={carouselImage} alt="home" />
        </div>
        <div className="text2">Boost Your Cash Flow in Minutes.</div>
        <div className="pt-3 text3">
        Finley can automatically create a personalized spending plan that that helps optimize your cashflow.
        </div>
      </div>

      {/* Action Button */}
      <div className="w-full">
        <button
          onClick={handleClick}
          className="flex w-full items-center justify-center px-4 py-2 bg-neutral-900 text-white rounded-full space-x-1 text3"
        >
          <ArrowRight size={16} />
          <span>Create My Spending Plan</span>
        </button>
      </div>
    </div>
  );
};

export default SpendingAnalysisCard;
