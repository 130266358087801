import PropTypes from 'prop-types';
import AppLogo from '../../assets/BetaAppLogo';
import BackIcon from '../../assets/BackIcon';
import { FC } from 'react';
interface HeaderWithBackIconProps {
    onBackClick: () => void; 
}
const HeaderWithBackIcon: FC<HeaderWithBackIconProps> = ({ onBackClick }) => {
    return (
        <div className="relative flex items-center pt-4 pb-3">
            <div className="absolute " onClick={onBackClick}>
                <BackIcon />
            </div>

            <div className="mx-auto flex justify-center items-center">
                <AppLogo />
            </div>
        </div>
    );
}



export default HeaderWithBackIcon;
