import React, { useEffect, useRef } from "react";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";
import BackIcon from "../../assets/BackIcon";

interface FullRightDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  content: React.ReactNode;
  onBack?: () => void;
  closeIcon?: boolean;
  width?: string;
}

const FullRightDrawer: React.FC<FullRightDrawerProps> = ({
  isOpen,
  onClose,
  title,
  content,
  onBack,
  closeIcon = false,
  width = "w-full",
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
const goBack=()=>{
  if (onBack)
   {
    
    if (contentRef.current) {
      contentRef.current.scrollTop = 0; // Reset scroll position
    }
    onBack();
   }
    
}
  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.scrollTop = 0; // Reset scroll position
    }
  }, [onBack]);

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10 w-full h-full bg-white" onClose={onClose}>
        <div className="fixed inset-0 bg-neutral-100 bg-opacity-50" />
        <div className="fixed inset-0 flex justify-center">
          <div className={`fixed inset-y-0 left-0 flex ${width} max-h-screen hide-scrollbar`}>
            <Transition.Child
              as={React.Fragment}
              enter="transition transform ease-out duration-300"
              enterFrom="translate-x-[100%]"
              enterTo="translate-x-0"
              leave="transition transform ease-in duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-[100%]"
            >
              <DialogPanel className="pointer-events-auto w-full transform shadow-xl bg-white h-full flex flex-col overflow-hidden">
                <div className="flex flex-col pt-3 h-full">
                  <div className="flex justify-between items-center w-full px-4">
                    {onBack && (
                      <button
                        onClick={goBack}
                        className="text-neutral-700 text-2xl"
                        aria-label="Back"
                      >
                        <BackIcon />
                      </button>
                    )}
                    <div className="flex-1 text-center">{title}</div>
                    {closeIcon && (
                      <button
                        onClick={onClose}
                        className="text-neutral-700 text-2xl"
                        aria-label="Close"
                      >
                        <span>&times;</span>
                      </button>
                    )}
                  </div>
                  <div className="border-solid border-b border-neutral-200 pt-3"></div>

                  {/* Content area */}
                  <div ref={contentRef} className="relative flex-1 overflow-y-auto px-4 py-2 hide-scrollbar mb-10">
                    <div className="text-large400 font-serif">{content}</div>
                  </div>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FullRightDrawer;
