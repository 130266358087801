import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
interface CarouselProps {
  slides: { title: string; description: string; image: string }[];
  interval?: number;
}

const Carousel: React.FC<CarouselProps> = ({ slides, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  // useEffect(() => {
  //   const autoplay = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  //   }, interval);

  //   return () => {
  //     clearInterval(autoplay);
  //   };
  // }, [slides.length, interval]);

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
    setOffset(0);
  };
  const swipeHandlers = useSwipeable({
    onSwiping: (eventData) => {
      setOffset(eventData.deltaX);
    },
    onSwipedLeft: () => {
      if (currentIndex < slides.length - 1) {
        goToSlide(currentIndex + 1);
      } else {
        setOffset(-50); // Spring effect
        setTimeout(() => setOffset(0), 200);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        goToSlide(currentIndex - 1);
      } else {
        setOffset(50); // Spring effect
        setTimeout(() => setOffset(0), 200);
      }
    },
    trackMouse: true,
  });

  return (

    <div className="relative  w-full max-w-md mx-auto mt-3"     >
      <div className="overflow-hidden rounded-2xl">
        <div
          className="flex transition-transform duration-500 ease-in-out  "
          // style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          style={{ transform: `translateX(calc(-${currentIndex * 100}% + ${offset}px))` }}
          {...swipeHandlers}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0 p-[25px] text-center bg-white rounded-2xl shadow-md "
            >
              <img
                src={slide.image}
                alt={slide.title}
                className="w-[319px] h-[288] object-cover mx-auto rounded-2xl "
              />
              <div className="text-left carouselTitle mb-2 tracking-[-0.005em]">{slide.title}</div>
              <div className="text-left carouselSubTitle">{slide.description}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center space-x-2 mt-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`h-[5px] w-5 rounded-full ${index === currentIndex ? 'bg-black' : 'bg-gray-300'
              }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;


