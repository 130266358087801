import * as React from 'react';
import MonthlySpendLineChart from '../Charts/MonthlySpendLineChart';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { categoriesImage } from '../../common/categories';
import { fetchCategorySpendsAsync } from '../../../store/thunks/SpendingThunk';
import { fetchSpendingCategoryTransactionAsync } from '../../../store/thunks/TransactionThunk';
import { useEffect, useState } from 'react';
import { FinleyCategorySpentData } from '../../../store/slices/SpendingsSlice';
import CategorySpendingChart from '../Charts/CategorySpendingChart';
import { useNavigate } from 'react-router';
import DrawerFullHeight from '../../common/DrawerFullHeight';
import SpendCategoryData from '../Spendings/SpendCategoryData';
import { trackPageView } from '../../../utils/trackPageView';

export interface ISpendingScreenWidgetProps {
    activeTab: any;
    categorySelected: () => void;
}

export default function SpendingScreenWidget({ activeTab, categorySelected }: ISpendingScreenWidgetProps) {
    const dispatch = useDispatch<AppDispatch>();
    const [selectedCategory, setSelectedCategory] = useState<FinleyCategorySpentData>();
    const [categorySpending, setCategorySpending] = useState(false);
    const [editAmount, setEditAmount] = useState<number | null>(null);
    const [editCategory, setEditCategory] = useState<string | null>(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const sessionId = sessionStorage.getItem("session_id");

    const navigate = useNavigate();


    const categorySpentResponseState = useSelector(
        (state: RootState) => state.spendingStore.fetchCategorySpentResponse
    );


    useEffect(() => {
        dispatch(fetchCategorySpendsAsync());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchSpendingCategoryTransactionAsync(selectedCategory?.finleyCategory));
    }, [dispatch, selectedCategory]);

    const goToSpending = () => {
        categorySelected();
    }

    const spendCategoryData = () => {
        // const categoryData = categoriesImage.find(
        //     (item) => item.categoryName.toLowerCase() === selectedCategory?.finleyCategory.toLowerCase()
        // );
        return (
            <SpendCategoryData
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
            />
        );
    };
    const sortedCategories = categorySpentResponseState
        ? [...categorySpentResponseState] // Create a shallow copy of the array
            .sort((a, b) => {
                // Sort by spendPercentage in descending order
                if (b.spendPercentage !== a.spendPercentage) {
                    return b.spendPercentage - a.spendPercentage;
                }
                // If spendPercentage is the same, sort by finleyCategory alphabetically
                return a.finleyCategory.localeCompare(b.finleyCategory);
            })
            .slice(0, 4) // Take the top 4
        : [];

    return (
        <>
            <div className='text2 py-3'>Your Spendings</div>
            <div className="flex  pt-[25px] px-[25px] flex-col gap-[25px] self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white mb-3">
                <div onClick={() => {
                    goToSpending()
                }}><MonthlySpendLineChart></MonthlySpendLineChart></div>

                <div className="flex flex-wrap justify-centre ">
                    {sortedCategories.map((category) => (
                        <div onClick={() => {
                            setCategorySpending(true)
                            setSelectedCategory(category)
                            trackPageView(activeTab, "spending_category_drawer", sessionId);
                        }} className="" style={{ width: '25%' }} key={category.finleyCategory}>
                            <CategorySpendingChart
                                finleyCategory={category.finleyCategory}
                                spendPercentage={category.spendPercentage}
                                spendStatus={category.spendStatus}
                                spendRemaining={category.spendRemaining || 0}
                            />
                        </div>
                    ))}


                </div>
                <button onClick={() =>{trackPageView(activeTab, "analyze_spending", sessionId); navigate('/overview')}} className="bg-neutral-100 px-4 py-2 text-neutral-900 rounded-full text3 w-full">
                    Review Spending Suggestions
                </button>
                <div />

            </div>
            <DrawerFullHeight border={true} isOpen={categorySpending} onClose={function (): void {
                setCategorySpending(false);
                trackPageView("spending_category_drawer ", activeTab, sessionId);

            }} title={selectedCategory?.finleyCategoryName} content={spendCategoryData()} onBack={function (): void {
                setCategorySpending(false);
                trackPageView("spending_category_drawer ", activeTab, sessionId);
            }} />
        </>
    );
}
