import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { STATUS, resetForgotPasswordStatus } from "../../../store/slices/AuthSlice";
import Header from "../../common/Header";
import BackIcon from "../../../assets/BackIcon";
import { resetPasswordAsync } from "../../../store/thunks/AuthThunk";
import useDeviceInfo from "../../../hooks/useDeviceInfo";
import { Keyboard } from "@capacitor/keyboard";
import HeaderBigLogo from "../../common/HeaderBigLogo";
interface EmailState {
    email: string;
}

const ForgotPassword = () => {
    const deviceInfo = useDeviceInfo();
    const navigate = useNavigate();
    const [email, setEmail] = useState<EmailState>({ email: "" });
    const [message, setMessage] = useState({ text: "", type: "" }); // Unified message state
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isResend, setIsResend] = useState(false); // Flag for resend action
    const dispatch = useDispatch<AppDispatch>();
    const AuthStore = useSelector((state: RootState) => state.AuthStore);

    const handleResetPassword = async (resend: boolean = false) => {
        setIsResend(resend); // Set resend flag
        dispatch(resetPasswordAsync(email));
    };
    useEffect(() => {

        if (deviceInfo?.platform === "ios") {

            const showListener = Keyboard.addListener("keyboardWillShow", (info) => {


                document.body.classList.add("keyboard-open");

            });
            const hideListener = Keyboard.addListener("keyboardWillHide", () => {
                document.body.classList.remove("keyboard-open");

            });
            return () => {
                document.body.classList.remove("keyboard-open");
                Keyboard.removeAllListeners();
            };
        }
    }, [deviceInfo?.platform]);
    useEffect(() => {
        if (AuthStore.resetPasswordStatus === STATUS.SUCCESS) {
            if (isResend) {
                setMessage({ text: "Email resent successfully.", type: "success" });
            } else {
                setMessage({ text: "Email sent successfully.", type: "success" });
                setIsEmailSent(true);
            }
        } else if (AuthStore.resetPasswordStatus === STATUS.FAILED) {
            setMessage({
                text: AuthStore.resetPasswordError?.message || "An error occurred. Please try again.",
                type: "error",
            });
        } else {
            setMessage({ text: "", type: "idle" })
        }
    }, [AuthStore.resetPasswordStatus, isResend]);

    const renderMessage = () => {
        if (!message.text) return null;
        const messageClass = message.type === "success" ? "text-green-500" : "text-red-500";
        return <div className={`${messageClass} mt-2 text-center`}>{message.text}</div>;
    };

    return (
        <div className="flex flex-col pt-3 px-2.5 h-screen">
            {!isEmailSent ? (
                <>
                    <div onClick={() => {
                        navigate("/login")
                        dispatch(resetForgotPasswordStatus())
                    }
                    }>
                        <BackIcon />
                    </div>
                    <div className="mt-28">
                        <HeaderBigLogo />
                    </div>
                    <div className="texth2 flex justify-center">Reset your password.</div>
                    <div className="carouselSubTitle text-center mt-3">
                        If your account exists, you’ll receive an email to reset your password.
                    </div>
                    <div className="carouselSubTitle flex justify-start items-start mt-6 ml-1">Email</div>
                    <input
                        type="text"
                        value={email.email}
                        onChange={(e) => setEmail({ email: e.target.value })}
                        placeholder="Enter your email"
                        className="px-4 pr-10 py-3 w-full resize-none mt-2 rounded-md overflow-hidden border h-12 border-customSlate"
                    />
                    <button
                        className={`mt-6 h-10 w-full rounded-full text-lg  ${email.email ? "bg-loginBg text-white" : "bg-gray-400 text-white"
                            }`}
                        onClick={() => handleResetPassword(false)}
                        disabled={!email.email}
                    >
                        Send reset link
                    </button>
                    {renderMessage()}
                </>
            ) : (
                <>
                    <div onClick={() => {
                        setIsEmailSent(false)

                        dispatch(resetForgotPasswordStatus())
                    }}>
                        <BackIcon />
                    </div>
                    <div className="mt-60">
                        <HeaderBigLogo />
                    </div>
                    <div className="texth2 flex justify-center">Email sent</div>
                    <div className="carouselSubTitle text-center mt-3">
                        Didn’t get an email?{" "}
                        <span
                            className="text-blue600 cursor-pointer"
                            onClick={() => {
                                setMessage({ text: "", type: "" }); // Clear existing message
                                handleResetPassword(true);
                            }}
                        >
                            Resend
                        </span>
                    </div>
                    {isResend && renderMessage()}
                </>
            )}
        </div>
    );
};

export default ForgotPassword;
