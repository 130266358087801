import React, { forwardRef, useEffect, useState } from 'react';
import Drawer from '../../common/Drawer'
import { TERMS_AND_PRIVACY } from '../../../types/CommonType';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { saveAccountAndAcknowledge } from '../../../store/thunks/OnBoardingThunk';
import { AccountCreationStatus, updateAcknowledgeDate, validateStep } from '../../../store/slices/OnBoardingSlice';
import { MESSAGES } from '../../../types/CommonType';
import MessageComponent from '../../common/MessageComponent';
import { useNavigate } from "react-router";
import mixpanel, { identifyUser } from '../../../utils/mixpanel';
import TypeWriterText from '../chat/TypewriterText';

const Step4 = forwardRef<HTMLDivElement>((props, ref) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<{ title: string; content: React.ReactNode } | null>(null);
    const phone = useSelector((state: RootState) => state.OnboardingStore.phone);
    const password = useSelector((state: RootState) => state.OnboardingStore.password);
    const accountCreationStatus = useSelector((state: RootState) => state.OnboardingStore.accountCreationStatus);
    const userOnboarding = useSelector((state: RootState) => state.OnboardingStore.userOnboarding);
    const [isVerifying, setIsVerifying] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [typingComplete, setTypingComplete] = useState(false); // Tracks if introText typing is complete

    useEffect(() => {
        dispatch(validateStep({ step: 'step4', isValid: true }));
    }, [])

    const handleOpenDrawer = (type: 'terms' | 'privacy') => {
        const selectedContent = type === 'terms' ? TERMS_AND_PRIVACY.terms : TERMS_AND_PRIVACY.privacy;
        setDrawerContent(selectedContent);
        setIsDrawerOpen(true);
    };

    const updateAcknowledgementDate = async (field: string) => {

        if (field === 'termsDate') {
            dispatch(updateAcknowledgeDate({ field: 'termsDate' }));
        } else {
            dispatch(updateAcknowledgeDate({ field: 'privacypolicyDate' }));
        }
    }
    // useEffect(() => {
    //   if (acknowledge.privacypolicyDate && acknowledge.termsDate)
    //   {

    //   }
    //   else
    //   {
    //     setError('Please confirm')
    //   }

    // }, [acknowledge])

    // useEffect(() => {
    //     if (isVerifying) {
    //         if (accountCreationStatus === AccountCreationStatus.SUCCESS) {
    //             setIsVerifying(false);
    //             const accessToken: string | undefined = userOnboarding?.accessToken;
    //             localStorage.setItem('accessToken', accessToken as string);
    //             identifyUser(accessToken, 'Onboard Step4 Complete');
    //             navigate('/new-connect-account')
    //         } else if (accountCreationStatus === AccountCreationStatus.FAILED) {
    //             setIsVerifying(false);
    //             setError(MESSAGES.ACCOUNT_CREATION_FAILED);
    //         }
    //     }

    // }, [accountCreationStatus, isVerifying, userOnboarding])

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
        setDrawerContent(null); // Clear content when closing
    };

    const handleAccountCreation = async () => {
        setError('');
        setIsVerifying(true);
        try {
            const payload = { phoneNumber: phone, password: password };
            const result = await dispatch(saveAccountAndAcknowledge(payload));
            if (saveAccountAndAcknowledge.fulfilled.match(result))
            {
                setIsVerifying(false);
                const accessToken: string | undefined = result.payload.accountAcknowledgeResult?.accessToken;
                localStorage.setItem('accessToken', accessToken as string);
                identifyUser(accessToken, 'Onboard Step4 Complete');
                navigate('/new-connect-account') 
            }
            mixpanel.track("Onboard Flow", {
                'Onboarding Step': 'Account Creation'
            });
            mixpanel.track("User Sign Up Step Completed");
            console.log('Account and Acknowledge succeeded:', result);
        } catch (error) {
            console.error('Error saving account or acknowledging:', error);
        }

    }
    useEffect(() => {
        if (typingComplete) {
            // After typing is complete, find the buttons using ID and attach event listeners
            const termsButton = document.getElementById('terms-button');
            const privacyButton = document.getElementById('privacy-button');
            if (termsButton) {
                termsButton.addEventListener('click', () => {
                    updateAcknowledgementDate('termsDate'); // Update acknowledgement for terms
                    handleOpenDrawer('privacy'); // Open privacy drawer
                });
            }
            if (privacyButton) {
                privacyButton.addEventListener('click', () => {
                    updateAcknowledgementDate('privacypolicyDate'); // Update acknowledgement for privacy
                    handleOpenDrawer('privacy'); // Open privacy drawer
                });
            }
        }
    }, [typingComplete]);
    return (
        <>
            <div className='rounded-2xl  min-h-80 bg-white border-slate-200 p-[25px] border pt-4' >
                <div ref={ref}></div>
                <TypeWriterText  html={`<div>
    <h2 class="text-neutral-900 font-serif body-medium mb-2">
        Finley always protects your financial information and privacy.
    </h2>
    <div class="mb-2 text-gray-800">
        Your privacy and security are super important to me. Here's what you should know:
    </div>
    <ul class="list-disc pl-6 text-gray-800">
        <li>Your financial data is protected with bank-level encryption</li>
        <li>Your employer will never see your personal financial information</li>
    </ul>
</div>
<div class="flex flex-col font-serif text-gray-800 pt-4">
    <p>By continuing, you agree to our 
        <button id="terms-button"  class="text-blue600 hover:underline">
            Terms of Service
        </button> and 
        <button id="privacy-button"   class="text-blue600 hover:underline">
            Privacy Policy.
        </button>
    </p>
</div>`} handleTextUpdate={setTypingComplete} />
            </div>
            {typingComplete && <>


                <div className="w-full pt-2">
                    <button className="flex justify-center items-center cursor-pointer w-full bg-loginBg text3 text-white py-2 rounded-full" onClick={handleAccountCreation} >
                        Acknowledge
                    </button>
                </div>
                {error && <div className='pt-2'>
                    <MessageComponent type="Error" message={error}></MessageComponent>
                </div>}
            </>}
            {isDrawerOpen && drawerContent && (
                <Drawer
                    isOpen={isDrawerOpen}
                    onClose={handleCloseDrawer}
                    title={drawerContent?.title || ''}
                    content={drawerContent?.content || ''}
                />
            )}


        </>
    );
});

export default Step4;

