import React, { useEffect } from 'react';

const Support = () => {  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.id = 'tally-js';
    script.onload = () => {
        // Wait until the Tally script is loaded before calling the function
        if (window.Tally) {
            window.Tally.loadEmbeds();
        } else {
            console.error('Tally script did not load correctly.');
        }
    };
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script); // Clean up the script on component unmount
    };
}, []);
  return (
    <div className=''>

    <iframe data-tally-src="https://tally.so/embed/wgkQ8K?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="1025" frameborder="0" marginheight="0" marginwidth="0" title="Finley Support"></iframe>
    </div>
  );
};

export default Support;
