import React, { useState, useEffect } from "react";
import TypeWriterText from "../pages/chat/TypewriterText";

const ThinkingAnimation = () => {
  const [dots, setDots] = useState(0);
  const messages = [".", "..", "..."];

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev + 1) % messages.length);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{  textAlign: "center" }} className="text3">
        <div className="flex ">
            <div className="text3"> <TypeWriterText html={"Thinking"} speed={60}/> </div>  <div className="text3">{messages[dots]}</div>
        </div>
   
    </div>
  );
};

export default ThinkingAnimation;
