
import React from "react";
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Scatter,
} from "recharts";
import greendot from '../../../assets/greendot.svg'
const data = Array.from({ length: 28 }, (_, i) => {
    const day = i + 1;
    const spendingLimitTillDate = (4000 / 28) * day; // Linear growth
    const amplitude = 1000; // Amplitude of oscillation
    const frequency = 0.5; // Controls the number of waves
    const spendingTillDate =
        spendingLimitTillDate + amplitude * Math.sin(frequency * day);

    return {
        day,
        spendingLimitTillDate,
        spendingTillDate: spendingTillDate >= 0 ? spendingTillDate : 0, // Ensure non-negative values
    };
});
interface MonthlyData {
    day: number;
    spendingTillDate?: number | null;
    spendingLimitTillDate: number;
}

interface LineChartProps {
    monthlyData?: MonthlyData[];
}
const customDot = (props: any) => {
    const { cx, cy } = props;

    // Prevent rendering if cx or cy is missing

    if (cy) {
        return <image x={cx - 6} y={cy - 6} href={greendot} width={12} height={12} />;
    }
    return <img />
};

const LineAreaChart: React.FC<LineChartProps> = ({ monthlyData }) => {
    const monthlyData1 = Array.from({ length: 28 }, (_, i) => {
        const day = i + 1;
        const spendingLimitTillDate = (4000 / 28) * day; // Linear growth

        // Introduce an oscillation effect
        const amplitude = 500; // How much it deviates above and below
        const frequency = 0.8; // Adjust frequency for better "C" and "U" shapes
        const spendingTillDate =
            spendingLimitTillDate + amplitude * Math.sin(frequency * day);

        return {
            day,
            spendingLimitTillDate,
            spendingTillDate, // Introduces the wavy pattern
        };
    });
    const today = new Date().getDate();


    function addTodaysSpending(data: MonthlyData[]) {
        const latestSpendItem = data.find(x => x.spendingTillDate == undefined);
        const latestDay = latestSpendItem && latestSpendItem.day !== undefined ? latestSpendItem.day - 1 : today;

        return monthlyData?.map(item =>
            item.day === latestDay && item.spendingTillDate !== undefined && item.spendingTillDate !== null
                ? { ...item, todaySpending: item.spendingTillDate }
                : { ...item, todaySpending: undefined } // Undefined values will be ignored
        )
    }

    return (
        <ResponsiveContainer width="100%" height={200}>
            <ComposedChart data={addTodaysSpending(monthlyData || [])} >
                <defs>
                    <linearGradient id="spendingGradient" x1="0" y1="0" x2="0" y2="1">

                        <stop stopColor="#22C55E" stopOpacity="0" />
                        <stop offset="1" stopColor="#22C55E" stopOpacity="0.5" />
                    </linearGradient>
                </defs>
                {/* dy to all vertical space after the x line */}
                <XAxis dataKey="day" axisLine={{ strokeWidth: 0.5, stroke: "#D4D4D4" }} interval={0}
                    tickFormatter={(day) => {
                        const baseDate = new Date(new Date().getFullYear(), new Date().getMonth(), day);
                        const dayString = day.toString().padStart(2, '0'); // Pad the day to 2 digits
                        return baseDate.getDay() === 1 ? dayString : ""; // Show only if it's Monday
                    }} tickLine={false} tick={{
                        dy: 10, style: {
                            fill: "#737373",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            lineHeight: "20px",

                        }
                    }} />
                <YAxis hide={true} />


                <Area fill="url(#spendingGradient)" type="monotone" dataKey="spendingTillDate" strokeWidth={4} stroke="#22C55E" dot={false}
                    activeDot={false} />
                <Line type="monotone" dataKey="spendingLimitTillDate" strokeLinecap="round" stroke="#D4D4D4" dot={false} strokeWidth={2} strokeDasharray="5 10" />
                <Scatter name="todaySpending" dataKey="todaySpending" fill="#22C55E" shape={customDot} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default LineAreaChart;
