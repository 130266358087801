import FinleyLogo from '../../assets/FinleyLogo28.svg';
import bell from '../../assets/bell.svg';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import AccountHome from '../pages/Accounts/AccountHome';



const GreetingHeader = ({ pageViewTrack }: { pageViewTrack: ( source: string,tabName?: string,) => void }) => {
    const userName = useSelector((state: RootState) => state.MasterStore.userAccount.first);

    return (
        <header className="sticky top-0 bg-customBgColor w-full z-10 ">
            <div className="flex justify-between items-center w-full px-2 py-3">
                {/* Logo and Greeting */}
                <div className="flex items-center gap-4">
                    <img className="size-7" src={FinleyLogo} alt="Finley Logo" />
                    <span className="texth2">Hi, {userName}</span>
                </div>

                {/* Notification and User Icons */}
                <div className="flex items-center gap-2">
                    <button className="h-8 w-8 rounded-full bg-white flex items-center justify-center shadow-md hover:shadow-lg">
                        <img src={bell} alt="notification" />
                    </button>
                    <button className="h-8 w-8 rounded-full bg-white flex items-center justify-center shadow-md hover:shadow-lg">
                        <AccountHome pageViewTrack={pageViewTrack}></AccountHome>
                    </button>
                </div>
            </div>
        </header>
    );
};
export default GreetingHeader;
