import mixpanel from 'mixpanel-browser';
import { jwtDecode } from 'jwt-decode';

const projectKey = process.env.REACT_APP_MIXPANEL_PROJECT_KEY;


// Initialize Mixpanel with your project token
mixpanel.init(projectKey, 
    {   debug: false, 
        track_pageview: true,  
        persistence: "localStorage", 
        autocapture: true,
        record_sessions_percent: 10 });

export const identifyUser = (accessToken: string | null | undefined, eventType: string) => {
    if (!accessToken) {
        console.warn("No access token provided for Mixpanel identification.");
        return;
    }
    try {
        const decodedToken: any = jwtDecode(accessToken);
        const userId = decodedToken.sub; 
        const userEmail = decodedToken.email;
        const userRole = decodedToken.role;
        const organizationId = decodedToken.organization_id;
        mixpanel.identify(userId);
        mixpanel.people.set({
            $email: userEmail,
            role: userRole,
            organization_id: organizationId,
            login_time: new Date().toISOString(),
        });
        mixpanel.track(eventType);
        console.log(`Mixpanel identified user: ${userId}`);
    } catch (error) {
        console.error("Failed to decode JWT token for Mixpanel:", error);
    }
};

export default mixpanel;
