import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { Main } from './components/common/Main';
import { useEffect, useState } from 'react';
import { SafeArea } from 'capacitor-plugin-safe-area';
import mixpanel from './utils/mixpanel';
import { useLocation } from 'react-router-dom';

const TrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    mixpanel.track('Page Viewed', { page: location.pathname });
  }, [location]);
  return null;
};

function App() {
  const [appInsets, setAppinsets] = useState({ top: 0, bottom: 0 });

  useEffect(() => {
    (async function () {
      const safeAreaData = await SafeArea.getSafeAreaInsets();
      const { insets } = safeAreaData;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${value}px`
        );
      }
    })();
  }, []);

  useEffect(() => {
    const setupWithInsets = async function () {
      const insets = await SafeArea.getSafeAreaInsets();
      setAppinsets({ top: insets.insets.top, bottom: insets.insets.bottom });
    };
    setupWithInsets();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <TrackPageView /> {/* ✅ This is inside BrowserRouter now */}
        <div className='pb-safe toolbar'>
          <Main />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
