
import { useEffect, useState } from 'react';
import FullRightDrawer from '../../common/FullRightDrawer';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDailySpendsAsync } from '../../../store/thunks/SpendingThunk';
import { formatCurrency } from '../../../utils/helper';
import lifebuoy from '../../../assets/life-buoy.svg';
import building from '../../../assets/building-2.svg';
import signout from '../../../assets/arrow-left-circle.svg';
import user from '../../../assets/user.svg';
import SignOutModal from './SignOutModal';
import { useNavigate } from 'react-router';
import { resetLoginStatus } from '../../../store/slices/AuthSlice';
import { Settings } from './Settings';
import FinancialAccountConnect from './FinancialAccountConnect';
import ActionModal from '../../common/ActionModal';
import { fetchAccountSummaryByTypeAsync } from '../../../store/thunks/TransactionThunk';
import { resetLinkToken } from '../../../store/slices/TransactionSlice';
import mixpanel from '../../../utils/mixpanel';
import Support from './Support';


type Props = {};
export default function AccountHome({ pageViewTrack }: { pageViewTrack: (source: string, tabName?: string) => void }) {
    const dispatch = useDispatch<AppDispatch>();
    const [isMainDrawerOpen, setIsMainDrawerOpen] = useState(false); // Main drawer state
    const [activeSection, setActiveSection] = useState<string | null>(null); // Active section
    const [showSignOutModal, setShowSignOutModal] = useState(false); // Modal state
    const userName = useSelector((state: RootState) => state.MasterStore.userAccount);
    const spendingState = useSelector((state: RootState) => state.spendingStore.fetchDailySpendsResponse);
    const accountSummaryTypeList = useSelector((state: RootState) => state.TransactionStore.accountSummaryTypeList);
    const navigate = useNavigate();

    const openMainDrawer = () => { setIsMainDrawerOpen(true); pageViewTrack('internal_link', 'Settings',) };
    const closeMainDrawer = () => { setIsMainDrawerOpen(false); pageViewTrack('internal_link') }
    useEffect(() => {
        dispatch(fetchAccountSummaryByTypeAsync());
    }, []);

    // Manage sign-out actions
    const handleSignOut = () => {
        localStorage.removeItem('accessToken');
        sessionStorage.removeItem("session_id");
        dispatch(resetLoginStatus());
        navigate('/landing');
        setShowSignOutModal(false); // Close modal
        mixpanel.reset();
    };

    const handleCancelSignOut = () => setShowSignOutModal(false);

    // Fetch daily spends data
    useEffect(() => {
        const fetchDailySpends = async () => {
            await dispatch(fetchDailySpendsAsync());
        };
        fetchDailySpends();
    }, [dispatch]);
    useEffect(() => {
        if (activeSection) {
            pageViewTrack("settings", activeSection);
        }
    }, [activeSection]);
    // Handle back action (Close overlay or reset to manage settings)
    const handleBack = () => {
        if (activeSection === 'Connected Accounts') {
            dispatch(resetLinkToken());
        }
        if (activeSection) {
            console.log("acaaaaaaaaaa",activeSection)
            pageViewTrack(activeSection, "Settings");
        }
        setActiveSection(null); // Reset to ManageSettings

    };

    // Render different content based on the active section
    const renderSectionContent = () => {

        if (activeSection === 'Account Details') {
            return <Settings activeSection="Account Details" />;
        } else if (activeSection === 'Support') {
            return <div className="flex flex-col "><Support></Support></div>;
        } else if (activeSection === 'Connected Accounts') {
            return <FinancialAccountConnect />;
        } else {
            return renderManageSettings(); // Default is ManageSettings
        }
    };

    const renderAccountSummary = () => {
        return (
            accountSummaryTypeList.map(item => {
                return (
                    <div className='flex justify-between items-start self-stretch' key={item.type}>
                        <div className='intertextxs text-neutral-500'>{item.type}</div>
                        <div className='intertextxs text-neutral-500'>{formatCurrency(item.availableBalanceAmount)}</div>
                    </div>
                );
            })

        )
    };
    const renderManageSettings = () => (
        <div className="flex flex-col items-start gap-6 pt-6 pb-5">
            <div className="flex flex-col items-center gap-3 self-stretch">
                <img src={user} alt="user" />
                <div className="textlarge700">{userName.first} {userName.last}</div>
                <div className="texth2">
                    {formatCurrency(spendingState?.remainingLimit != null && spendingState.remainingLimit < 0
                        ? 0
                        : spendingState?.remainingLimit || 0)}
                </div>
                <div className="intertextxs text-neutral-500">of {formatCurrency(spendingState?.monthlyLimit || 0)} monthly limits</div>
            </div>

            <div className="flex flex-col gap-3 self-stretch">
                <div className="textlarge700">Financial Overview</div>
                {renderAccountSummary()}
            </div>

            <div className="flex flex-col gap-3 self-stretch">
                <div className="textlarge700">Manage your settings</div>
                {/* Support Button */}
                <div className="flex justify-center items-center self-stretch" onClick={() => setActiveSection('Support')}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <img src={lifebuoy} alt="support" />
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className="intertextsmall">Support</div>
                            <div className="intertextxs text-neutral-500">Get in touch with customer support.</div>
                        </div>
                    </div>
                </div>

                {/* Account Details Button */}
                <div className="flex justify-center items-center gap-2 self-stretch" onClick={() => setActiveSection('Account Details')}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <img src={user} alt="account" />
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className="intertextsmall">Account Details</div>
                            <div className="intertextxs text-neutral-500">Manage your login information.</div>
                        </div>
                    </div>
                </div>

                {/* Financial details */}
                <div className="flex justify-center items-center gap-2 self-stretch" onClick={() => setActiveSection('Connected Accounts')}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <img src={building} alt="account" />
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className="intertextsmall">Financial Accounts</div>
                            <div className="intertextxs text-neutral-500">Manage your connected accounts.</div>
                        </div>
                    </div>
                </div>

                {/* Sign Out Button */}
                <div className="flex justify-center items-center gap-2 self-stretch" onClick={() => setShowSignOutModal(true)}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <img src={signout} alt="signout" />
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className="intertextsmall">Sign Out</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {/* Main Logo with onClick to open the main drawer */}
            <img
                className="size-7 cursor-pointer"
                src={user}
                alt="user"
                onClick={openMainDrawer}
            />


            <FullRightDrawer
                isOpen={isMainDrawerOpen}
                onClose={closeMainDrawer}
                title={<div className="carouselTitle">{activeSection || "Manage Settings"}</div>}
                content={renderSectionContent()}
                closeIcon={!activeSection}
                onBack={activeSection ? handleBack : undefined}
                width="w-full"

            />

            {/* Render SignOut Modal */}
            {showSignOutModal && (
                <ActionModal
                    isOpen={showSignOutModal}
                    onClose={handleCancelSignOut}
                    onAction={handleSignOut}
                    message="Are you sure you want to sign out?"
                    cancelText="Cancel"
                    actionText="Sign Out"
                />
            )}
        </div>
    );
}
