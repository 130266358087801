import React from 'react';
import Header from '../../common/Header';

import Carousel from "../../common/Carousel";
import image1 from "../../../assets/carouselImage1.svg";
import image2 from "../../../assets/carouselImage2.png";
import image3 from "../../../assets/carouselImage3.png";
import image4 from "../../../assets/carouselImage4.png";

import { useNavigate } from "react-router";

import { resetOnboardingState } from "../../../store/slices/OnBoardingSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import mixpanel from '../../../utils/mixpanel';
import HeaderBigLogo from '../../common/HeaderBigLogo';

const LandingState = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const slides = [
        {
            title: 'Talk to Your Money',
            description: 'Get instant answers about your money in one place—from “How much did I spend on Uber?” to “Can I afford my dream vacation?”',
            image: image1, // Replace with your image URLs
        },
        {
            title: 'A Money Plan That Fits Your Life',
            description: 'Stop stressing about budgets. Our system adapts to your spending, creating a realistic, flexible plan that fits your lifestyle.',
            image: image2,
        },
        {
            title: 'Turn Your Goals into Reality',
            description: 'Turn big goals into simple steps. From emergency funds to retirement, we help you make steady progress on what matters.',
            image: image3,
        },
        {
            title: 'Get Expert Guidance Anytime',
            description: 'Access unlimited coaching from Accredited Financial Counselors® who understand your situation.',
            image: image4,
        }
    ];
    return (
        <div className="flex flex-col justify-center min-h-screen bg-customBgColor mx-3 ">
            {/* <Header /> */}
            <HeaderBigLogo/>
            <div className="text6 text-center flex pb-3 justify-center tracking-[-0.0075em]">The smart, simple way to manage your money</div>

            <div className="text-center">
                
                
                <Carousel slides={slides} interval={10000} />
                {/* indicator dots */}
                <div className="text-center flex flex-col w-full my-3 ">
                    <button className="bg-blackButton carouselSubTitle text-white py-2 px-6 rounded-full " onClick={() => {
                        dispatch(resetOnboardingState());
                        navigate('/onboarding');
                        mixpanel.track("Onboard Flow", {
                            'Onboarding Step': 'Get Started'
                        });
                    }}>
                        Get Started
                    </button>
                    <button className="border border-neutral300 carouselSubTitle text-black py-2 px-6 rounded-full mt-2" onClick={() => navigate('/login')}>
                        Sign In
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LandingState;
