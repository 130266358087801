
import { useNavigate } from "react-router";
import mixpanel from "../../../utils/mixpanel";
import TypeWriterText from "../chat/TypewriterText";
import { useState } from "react";

type SurveySubmitProps = {
    onSubmit: () => void;
};
const SurveySubmit = ({ onSubmit }: SurveySubmitProps) => {
    const navigate = useNavigate();
    const [typingComplete, setTypingComplete] = useState(false);
    
    const handleSubmit = async() => {
        await onSubmit();
        mixpanel.track("Onboard Flow", {
            'Onboarding Step': 'Complete Survey'
        });
       
         navigate('/home');
    };

    return (
        <>
        <TypeWriterText handleTextUpdate={setTypingComplete} html={` <div class="px-3">
            <div class="mt-[10px] carouselTitle">
                Great! Thanks for filling me in.
            </div>
            <div class="text3 mt-2 text-darkBlue">
                I’ll check in periodically to see if things are feeling different.
            </div>
            <div class="text3 text-darkBlue mt-5">
                Here's what I recommend you do next:
            </div>
            <div class="text3 font-semibold text-darkBlue ">
                💬 Talk to Your Money
            </div>
            <div class="text3 text-darkBlue ">
                Ask me anything about your finances - from daily spending to big picture goals
            </div>
            <div class="text3 font-semibold text-darkBlue mt-5">
                🔗 Connect More Accounts
            </div>
            <div class="text3 text-darkBlue ">
                Add all your financial accounts to get a complete view of your money
            </div>
            <div class="text3 font-semibold text-darkBlue mt-5">
                📝 Create a Spending Plan
            </div>
            <div class="text3 text-darkBlue ">
                Let me help you automatically generate a plan to reduce spending and boost cashflow
            </div>
           
        </div>`}></TypeWriterText>
        <div className="w-full mt-7 ">
               {typingComplete && <button className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4"  onClick={handleSubmit} >
                    Let's Get Started
                </button>}
                
            </div>
       </>
    )
}
export default SurveySubmit;

