import Footer from "../../common/Footer";
import { useEffect, useState } from "react";
import Chat from "../chat/Chat";
import { HomeTabSection } from "./HomeTabSection";
import SpendingHome from "../Spendings/SpendingHome";
import CoachingPageHome from "../CoachingHome/CoachingPageHome";
import { useNavigate, useSearchParams } from "react-router-dom";
import GreetingHeader from "../../common/GreetingHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { getUserAccountDetailsAsync } from "../../../store/thunks/MasterThunk";
import { OnboardingState } from "../../../store/slices/MasterSlice";
import mixpanel from '../../../utils/mixpanel';
import { trackPageView } from "../../../utils/trackPageView";

// const Home = () => {
//     const [searchParams, setSearchParams] = useSearchParams();
//     const from = searchParams.get('from');

//     const [activeTab, setActiveTab] = useState('Home'); // Track the active tab
//     const selectTab = (tabName: string) => {

//         setActiveTab(tabName);
//     }
//     useEffect(() => {
//         if (from) {
//             setActiveTab(from);
//             searchParams.delete('from');
//             setSearchParams(searchParams);
//         }
//     }, [from]);
//     return (
//         <div className='flex flex-col'>
//             <div className="flex flex-col ">

//                 {activeTab !== 'Finley AI' && (
//                     <div className="max-h-screen">
//                         {/* Add your main content here */}
//                         {activeTab === 'Home' && <div className='pt-3'> <HomeTabSection selectTab={selectTab} ></HomeTabSection></div>}
//                         {activeTab === 'Spending' && <div className='pt-3'><SpendingHome selectTab={selectTab}></SpendingHome></div>}
//                         {activeTab === 'Meet Coach' && <div className='pt-3'> <CoachingPageHome selectTab={selectTab} ></CoachingPageHome></div>}
//                     </div>
//                 )}
//                 {activeTab === 'Finley AI' && <Chat isLoadedFromHomeScreen={true} selectTab={selectTab} />}

//                 <footer className="sticky bottom-0">
//                     {activeTab !== 'Finley AI' && (
//                         <Footer activeTab={activeTab}  selectTab={selectTab} />

//                     )}</footer>
//             </div>
//         </div>
//     );
// };
const Home = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get('from');
    const dispatch = useDispatch<AppDispatch>();
    const [activeTab, setActiveTab] = useState('Home'); // Track the active tab
    const { userAccount } = useSelector((state: RootState) => state.MasterStore);
    const navigate = useNavigate();
    const sessionId = sessionStorage.getItem("session_id");
    useEffect(() => {
        const fetchData = async () => {
            const resultAction = await dispatch(getUserAccountDetailsAsync());
            // Check if the action was successful
            if (getUserAccountDetailsAsync.fulfilled.match(resultAction)) {
                // Ensure userAccount is not undefined or null
                if (resultAction.payload && resultAction.payload.onboarding_state === OnboardingState.BANK_ACCOUNT_CONNECTED) {
                    navigate('/survey');
                }
                else if (resultAction.payload && resultAction.payload.onboarding_state === OnboardingState.ONBOARDING_COMPLETE) {

                }
                else {
                    navigate('/new-connect-account');
                }
            }
        };

        fetchData();
    }, [dispatch, navigate]);

    const selectTab = (tabName: string) => {
        setActiveTab(tabName);
    }
    const pageViewTrack = ( source: string,tabName?: string) => {
        trackPageView(source, tabName ?? activeTab, sessionId);
    };
    

    useEffect(() => {
        if (from) {
            setActiveTab(from);
            searchParams.delete('from');
            setSearchParams(searchParams);
        }
    }, [from]);
    // useEffect(() => {
    //     pageViewTrack( '',activeTab) 
    // }, []);
    return (
        <div className="flex flex-col h-screen">
            {userAccount?.first &&
                <>
                    <header className="sticky top-0 z-10">
                        {activeTab !== 'Finley AI' && (
                            <GreetingHeader pageViewTrack={pageViewTrack}/>
                        )}
                    </header>

                    <main className="flex-1 overflow-y-auto pb-4">
                        <div className="max-h-full">
                            {activeTab !== 'Finley AI' && (
                                <div className="flex flex-col">
                                    {/* Add your main content here */}
                                    {activeTab === 'Home' && <HomeTabSection activeTab={activeTab} selectTab={(tab) => {
                                        selectTab(tab)
                                        pageViewTrack( 'internal_link',tab)
                                    }
                                    } />}
                                    {activeTab === 'Spending' && <SpendingHome activeTab={activeTab} selectTab={(tab) => {
                                        selectTab(tab)
                                        pageViewTrack( 'internal_link',tab)
                                    }} />}
                                    {activeTab === 'Meet Coach' && <CoachingPageHome selectTab={(tab) => {
                                        selectTab(tab)
                                        pageViewTrack( 'internal_link',tab)
                                    }} />}
                                </div>
                            )}
                            {activeTab === 'Finley AI' && <Chat isLoadedFromHomeScreen={true} selectTab={(tab) => {
                                selectTab(tab)
                                pageViewTrack( 'internal_link',tab)
                            }} />}
                        </div>
                    </main>

                    <footer className="z-10 bg-white mt-auto">
                        {activeTab !== 'Finley AI' && (
                            <Footer activeTab={activeTab} selectTab={(tab) => {
                                selectTab(tab)
                                pageViewTrack('bottom_nav',tab)

                            }
                            } />
                        )}
                    </footer>

                </>
            }
        </div>
    );
};
export default Home;
