import { useState, useRef, useEffect } from "react";
import AppLogo from "../../../assets/BetaAppLogo";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { submitSurveyAsync } from "../../../store/thunks/SurveyThunk";
import SurveySubmit from "./SurveySubmitPage";
import { getUserAccountDetailsAsync } from "../../../store/thunks/MasterThunk";
import TypeWriterText from "../chat/TypewriterText";

const SurveyPage = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [responses, setResponses] = useState<string[]>([]);

    const [showOptions, setShowOptions] = useState<boolean[]>([]);
    const [answeredQuestions, setAnsweredQuestions] = useState<boolean[]>([]);

    const mainContainerRef = useRef<HTMLDivElement>(null);
    const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const headerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();
 
    const questions: { question: string; options: string[] | null }[] = [
        { question: "How stressed do you feel about your finances?", options: ["😌 I feel calm and in control", "😊 I feel mostly at ease", "😟 I feel somewhat stressed", "😰 I feel very stressed"] },
        { question: "After paying your bills each month, how much money do you typically have left?", options: ["😌 Plenty left over", "😊 A little extra", "😟 Just breaking even", "😰 Coming up short"] },
        { question: "How do you feel about your retirement savings?", options: ["😌 I'm on track", "😊 I've started saving", "😟 I'm behind", "😰 I haven't started"] },
        { question: "If you had a $1,000 emergency expense today, how would you feel about handling it?", options: ["😌 No problem at all", "😊 I could manage", "😟 It would be tough", "😰 I couldn't handle it"] },
        { question: "How do you typically handle your credit card balance?", options: ["😌 Always full payment", "😟 Partial payments", "😰 Minimum payments", "🚫 I don't have a credit card"] },
        { question: "Last question, do you have any student debt?", options: ["😟 Some student debt", "😰 A lot of student debt", "🚫 I don't have student debt"] },
        { question: "", options: null } // Empty question for SurveySubmit component
    ];
const [typingComplete, setTypingComplete] = useState<boolean[]>(Array(questions.length).fill(false));
    useEffect(() => {
        if (currentQuestion < questions.length) {
            setTypingComplete((prev) => {
                const updated = [...prev];
                updated[currentQuestion] = false;
                return updated;
            });

            setShowOptions((prev) => {
                const updated = [...prev];
                updated[currentQuestion] = false;
                return updated;
            });

            const questionText = questions[currentQuestion].question;
            const typingDuration = 800 + (questionText.length * 20);

            const timeout = setTimeout(() => {
                setTypingComplete((prev) => {
                    const updated = [...prev];
                    updated[currentQuestion] = true;
                    return updated;
                });

                setTimeout(() => {
                    setShowOptions((prev) => {
                        const updated = [...prev];
                        updated[currentQuestion] = true;
                        return updated;
                    });
                }, 800);
            }, typingDuration);

            return () => clearTimeout(timeout);
        }
    }, [currentQuestion]);

    // useEffect(() => {
    //     const submitSurvey = async () => {
    //         if (answeredQuestions.every(Boolean) && answeredQuestions.length == 6) {
    //             console.log("use effect");
    //             await dispatch(submitSurveyAsync(generatePayload()));
    //         }
    //     };
    //     submitSurvey();
    // }, [dispatch, answeredQuestions]);
    
    // useEffect(() => {
    //     if (answeredQuestions.length === questions.length - 1 && answeredQuestions.every(Boolean)) {
    //         dispatch(submitSurveyAsync(generatePayload()));
    //     }
    // }, [answeredQuestions, dispatch]);
    

    const handleOptionClick = (option: string, questionIndex: number) => {
        setResponses((prevResponses) => {
            const updatedResponses = [...prevResponses];
            updatedResponses[questionIndex] = option;
            return updatedResponses;
        });

        setAnsweredQuestions((prev) => {
            const updated = [...prev];
            updated[questionIndex] = true;
            return updated;
        });

        setTimeout(() => handleNextQuestion(), 800);
    };

    const handleNextQuestion = () => {
        setCurrentQuestion((prev) => Math.min(prev + 1, questions.length - 1));
        setTimeout(handleScrollToCurrentQuestion, 0);
    };

    const handleScrollToCurrentQuestion = () => {
        const headerHeight = headerRef.current?.offsetHeight || 0;
        const nextQuestionRef = questionRefs.current[currentQuestion + 1];
        if (nextQuestionRef && mainContainerRef.current) {
            const questionTop = nextQuestionRef.offsetTop;
            mainContainerRef.current.scrollTo({
                top: questionTop - headerHeight + 110,
                behavior: "smooth",
            });
        }
    };

    const generatePayload = () => ({
        questions: questions
            .map((question, index) => ({
                question: question.question,
                answer: responses[index] || "",
            }))
            .filter((item) => item.answer !== ""),
    });

    const submitSurvey = async () => {
        const resultAction = await dispatch(submitSurveyAsync(generatePayload()));

        if (submitSurveyAsync.fulfilled.match(resultAction)) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await dispatch(getUserAccountDetailsAsync());
        }
    };

    return (
        <div className="relative h-screen">
            <div ref={headerRef} className="flex justify-between pt-16 items-center px-3 pb-3 backdrop-blur-sm absolute left-0 w-full z-10">
                <div className="flex justify-center items-center mx-auto">
                    <AppLogo />
                </div>
            </div>
            <div className="h-full pt-32 pb-[1000px] overflow-y-auto scroll-smooth" ref={mainContainerRef}>
                <div className="relative w-full">
                    {questions.map((item, index) => (
                        <div
                        key={index}
                        id={index+'s'}
                        ref={(el) => (questionRefs.current[index] = el)}
                        //className={`w-full transition-all duration-500 ease-in-out mb-3 ${index > currentQuestion ? "hidden" : "block"}`}
                        className={`w-full transition-all duration-500 ease-in-out mb-3 ${index === currentQuestion 
                            ? 'fade-slide-in'
                            : 'fade-slide-out'
                            }`}
                        style={{
                            opacity: index === currentQuestion ? 1 : index < currentQuestion ? 1 : 0,
                        }}
                           
                        >
                            <div className="px-3">
                            {item.question && index < currentQuestion && (<div className="text3 text-darkBlue">{item.question}</div>)}
                                {item.question && index === currentQuestion && ( // Only type the current question
                                    <>
                                        <TypeWriterText
                                            html={`<div class="text3 text-darkBlue">${item.question}</div>`}
                                            handleTextUpdate={() => {
                                                setTypingComplete((prev) => {
                                                    if (prev[index]) return prev; // Prevent unnecessary updates
                                                    const updated = [...prev];
                                                    updated[index] = true;
                                                    return updated;
                                                });
                                            }}
                                        />
                                    </>
                                )}
                                {item.question && showOptions[index] && typingComplete[index] && (
                                    <div className="w-full mt-7">
                                        {item.options?.map((option, idx) => (
                                           <button
                                           key={idx}
                                           onClick={() => handleOptionClick(option, index)}
                                           className="bg-blackButton carouselSubTitle text-white w-full py-2 px-6 rounded-full mb-4"
                                           style={{
                                               opacity: answeredQuestions[index]
                                                   ? (responses[index] === option ? 1 : 0.5)
                                                   : 1,
                                               transition: 'opacity 0.3s ease, background-color 0.3s ease',
                                           }}
                                       >
                                           {option}
                                       </button>
                                        ))}
                                    </div>
                                )}
                                {
                                    typingComplete[6] && !item.question && <SurveySubmit onSubmit={submitSurvey} />
                                }
                            </div>
                        </div>
                    ))}
                    
                </div>

            </div>
        </div>
    );
};

export default SurveyPage;