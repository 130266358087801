
// import { motion } from "framer-motion";
// import { useEffect, useState } from "react";
// import DOMPurify from "dompurify";

// type TypeWriterTextProps = {
//   html: string;
//   animate?: boolean;
//   handleTextUpdate?: (isComplete: boolean) => void;
//   speed?:number
// };

// const TypeWriterText = ({ html, animate = true, handleTextUpdate, speed=50 }: TypeWriterTextProps) => {
//   const sanitizedHtml = DOMPurify.sanitize(html)
//   const words = sanitizedHtml.match(/<[^>]+>|\s+|[^<>\s]/g) || [];
//   const [displayedWords, setDisplayedWords] = useState<string[]>([]);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     if (!animate) {
//       setDisplayedWords(words);
//       if (handleTextUpdate) {
//         handleTextUpdate(true);
//       }
//       return;
//     }
    
//     if (words.length === 0) return;

//     const interval = setInterval(() => {
//       setDisplayedWords((prevWords) => {
//         if (currentIndex >= words.length) {
//           if (handleTextUpdate) {
//             handleTextUpdate(true);
//           }
//           clearInterval(interval);
//           return prevWords;
//         }
//         return [...prevWords, words[currentIndex]];
//       });

//       setCurrentIndex((prevIndex) => prevIndex + 1);
//     }, speed);

//     return () => clearInterval(interval);
//   }, [animate, currentIndex, sanitizedHtml, handleTextUpdate]);

//   if (!animate) {
//     return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
//   }
// console.log(displayedWords)
//   return (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//       dangerouslySetInnerHTML={{ __html: displayedWords.join("") }}
//     />
//   );
// };

// export default TypeWriterText;
// const useTypingEffect = (text: string, speed: number = 30, start: boolean, onComplete?: () => void) => {
//   const [displayedText, setDisplayedText] = useState("");
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//       if (!start) return; // Do nothing if typing should not start

//       let timer: NodeJS.Timeout | undefined;
//       if (currentIndex < text.length) {
//           const randomDelay = speed + Math.random() * 10 - 5; // Add slight randomization
//           timer = setTimeout(() => {
//               setDisplayedText((prev) => {
//                   const newText = prev + text[currentIndex];
//                   return newText;
//               });
//               setCurrentIndex((prev) => prev + 1);
//           }, randomDelay);
//       } else if (onComplete) {
//           onComplete(); // Call the onComplete callback when done
//       }

//       return () => {
//           if (timer) {
//               clearTimeout(timer);
//           }
//       };
//   }, [currentIndex, text, speed, onComplete, start]);

//   return displayedText;
// };
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";

type TypeWriterTextProps = {
  html: string;
  animate?: boolean;
  handleTextUpdate?: (isComplete: boolean) => void;
  speed?: number;
};

const TypeWriterText = ({
  html,
  animate = true,
  handleTextUpdate,
  speed = 30,
}: TypeWriterTextProps) => {
  const sanitizedHtml = html;//DOMPurify.sanitize(html);
  const words = sanitizedHtml.match(/<[^>]+>|\s+|[^<>\s]/g) || [];
  const [displayedWords, setDisplayedWords] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!animate) {
      setDisplayedWords(words);
      if (handleTextUpdate) {
        handleTextUpdate(true);
      }
      return;
    }

    if (words.length === 0) return;

    let timer: NodeJS.Timeout | undefined;

    if (currentIndex < words.length) {
      const randomDelay = speed + Math.random() * 10 - 5; // Add slight randomization
      timer = setTimeout(() => {
        setDisplayedWords((prevWords) => [...prevWords, words[currentIndex]]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, randomDelay);
    } else if (handleTextUpdate) {
      handleTextUpdate(true); // Call the onComplete callback when done
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentIndex, words, speed, animate, handleTextUpdate]);

  if (!animate) {
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  }

  return <div dangerouslySetInnerHTML={{ __html: displayedWords.join("") }} />;
};

export default TypeWriterText;
